import * as React from "react";
import AboutPageComponent from "src/components/pages/about-us";

const AboutPage = ({ location }) => {
  const copy = {
    abeAndRose: "Abraham and Rose Spetner",
    kenneth: "Kenneth Spetner",
    text: [
      `Abraham and Rose Spetner sold their first insurance policy in 1935.
    Together they built a business by providing affordable insurance solutions
    for a wide variety of needs. They established a reputation for being
    knowledgeable and honest, and their business grew.`,
      `Although many things
    have changed since 1935, a third and fourth generation continue to operate
    Spetner with the same goals: caring about each client and providing the
    best life insurance solutions. Whether you are looking for temporary
    term (risk) insurance, life time guaranteed death benefit or something in
    between, Spetner will help you navigate the maze of life insurance options
    and underwriting procedures to find the solution that best serves you. We
    have special expertise in the area of international underwriting and promise
    to find the most economical solution for your needs.`,
      `Our goal is to provide
    you with appropriate benefits for your lifestyle. Spetner, Benefits for Life.`,
    ],
  };
  return (
    <AboutPageComponent
      lang="en"
      location={location}
      copy={copy}
      pageTitle="About Us"
    />
  );
};

export default AboutPage;
